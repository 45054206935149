import styled from "styled-components";
import React from "react";

const StyledChatbox = styled.div`
  background: url("chatbox.svg") no-repeat;
  background-size: contain;
  height: 168px;
`;

type Props = {
  children?: React.ReactNode;
};

export const Chatbox: React.FC<Props> = ({ children }) => {
  return (
    <StyledChatbox>
      <div style={{ padding: "4px 0 0 2px" }}>{children}</div>
    </StyledChatbox>
  );
};
