import React from "react";
import styled, { keyframes } from "styled-components";
import { AnimatedBackground } from "../../Components/AnimatedBackground";
import { MenuButton } from "../../Components/Button";
import { LobbyPlayers } from "../../Components/LobbyPlayers";
import { MediumHeading, SmallHeading } from "../../Components/TextTypes";
import {
  useNextQuestionMutation,
  usePresenterStateQuery,
} from "../../store/presenterApi";
import { popcat } from "../ScreenSelector";

export const Lobby: React.FC = () => {
  const { data } = usePresenterStateQuery();
  const [dispatchNextQuestion] = useNextQuestionMutation();

  if (!data) return null;
  const { roomTitle, allHighscores, roomPasscode, playerCode } = data;
  const highscore = allHighscores.at(-1) || [];
  
  const handleStartGame = () => {
    popcat.play();
    if (!playerCode) {
      console.error("Missing code!");
      return;
    }
    dispatchNextQuestion({ code: playerCode });
  };

  return (
    <AnimatedBackground animate>
      <LobbyContainer>
        <Top>
          <StyledPlayerInfo>
            <div>
              <MediumHeading style={{ width: "200px" }}>
                {roomTitle}
              </MediumHeading>
              <SmallHeading>Players: {highscore.length}</SmallHeading>
            </div>
          </StyledPlayerInfo>
          <RoomCodeWrapper>
            <div>
              <PersonaCard>
                <RoomCodeHeading>ROOM CODE:</RoomCodeHeading>
                <RoomCode>{roomPasscode}</RoomCode>
                <YAQTagline>YAQ.APP</YAQTagline>
                <br />
                <MenuButton onClick={handleStartGame}>Start Quiz</MenuButton>
              </PersonaCard>
            </div>
          </RoomCodeWrapper>
          <StyleYaqootLobby src="./mascot_waving.png" alt="yaqoot waving" />
        </Top>
        <LobbyPlayers highscore={highscore} />
      </LobbyContainer>
    </AnimatedBackground>
  );
};

const Top = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 8vh;
`;

const LobbyContainer = styled.div`
  padding: 20px;
`;

const StyledPlayerInfo = styled.div`
  position: absolute;
  top: 0;
  left: 10;
`;

const RoomCodeHeading = styled.h2`
  text-align: center;
  margin: 0px;
  padding: 0px;
`;

const RoomCode = styled.h3`
  text-align: center;
  font-size: 36px;
  margin: 0px;
  margin-top: 12px;
  padding: 0px;
`;

const YAQTagline = styled.h4`
  text-align: center;
  font-size: 36px;
  margin: 0px;
  margin-top: 12px;
  padding: 0px;
`;

const personaTransition = keyframes`
  0% {
    clip-path: polygon(0 7%, 96% 0, 100% 94%, 5% 100%);
  } 
  25% {
    clip-path: polygon(0 7%, 76% 9%, 100% 94%, 5% 100%);
  }
  50% {
    clip-path: polygon(16% 2%, 90% 1%, 87% 86%, 5% 100%);  
  }
  75% {
    clip-path: polygon(16% 2%, 100% 13%, 80% 100%, 0 83%);
    }
  100%{
    clip-path: polygon(0 7%, 96% 0, 100% 94%, 5% 100%);
  }
`;

const PersonaCard = styled.div`
  margin-top: 96px;
  background-color: white;
  padding: 50px 160px;
  animation: 2.5s ${personaTransition} ease-in-out infinite;
`;

const StyleYaqootLobby = styled.img`
  height: 300px;
  position: absolute;
  top: 20vh;
  right: 10vw;
`;

const RoomCodeWrapper = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
  justify-content: center;
`;
