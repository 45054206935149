import socketIOClient, { Socket } from "socket.io-client";

export enum PlayerAction {
  JoinRoom = "join",
  SetNickname = "set nick",
  Chat = "chat",
  Answer = "answer",
  Recover = "recover",
}

export enum PresenterAction {
  NextQuestion = "next question",
  Reset = "reset game",
  SkipTimer = "skip",
  SubmitRoomCode = "admin",
}

export enum ServerMessages {
  Highscore = "highscore",
  Question = "question",
  Tick = "tick",
  ShowChat = "show chat",
  Reset = "reset",
  Ready = "ready",
  JoinedRoom = "joined room",
  NotAdmin = "not admin",
  RoomYouControl = "room you controll",
  SetNick = "set nick",
}

export const backendUrl = () => {
  if (window.location.hostname === "frontend.yaq.test.iterate.no") {
    return "https://backend.yaq.test.iterate.no";
  } else if (window.location.hostname === "yaq.app") {
    return "https://backend.yaq.app.iterate.no";
  } else {
    return "localhost:3030";
  }
};

let finalSocket: Socket;
export function getSocket() {
  if (!finalSocket) {
    finalSocket = socketIOClient(backendUrl(), {
      withCredentials: true,
    });
  }
  return finalSocket;
}
