import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { AnimatedBackground } from "../Components/AnimatedBackground";
import { Box } from "../Components/Box";
import { MenuButton } from "../Components/Button";
import { Input } from "../Components/Input";
import { Link } from "../Components/Link";
import { Logo } from "../Components/Logo";
import { popcat } from "./ScreenSelector";
import { navigate } from "@reach/router";
import {
  useGetStateQuery,
  useJoinRoomMutation,
  useRecoverSessionMutation,
} from "../store/playerApi";

export const JoinRoom: React.FC = () => {
  const [roomCode, setRoomCode] = useState<string>("");
  const [dispatchRoomCode] = useJoinRoomMutation();
  const [dispatchRecover] = useRecoverSessionMutation();
  const { data } = useGetStateQuery();

  const [error, setError] = useState(data?.alert?.severity === "error");

  useEffect(() => {
    setError(!!data?.alert?.severity);
  }, [data?.alert]);

  const handleRecover = () => {
    dispatchRecover({ data: {} });
  };

  return (
    <Wrapper animate>
      <LinkWrapper>
        <Link onClick={() => navigate("/admin")}>Quizmaster?</Link>
        <br />
        <Link onClick={handleRecover}>Join existing game?</Link>
      </LinkWrapper>
      <Logo />
      <Box
        transparent
        onFormSubmit={(e) => {
          e.preventDefault();
          popcat.play();
          dispatchRoomCode({ code: roomCode });
        }}
      >
        <Input
          type="number"
          min="0"
          error={error}
          onClick={() => setError(false)}
          placeholder={"Enter roomcode"}
          onChange={(e) => {
            setRoomCode(e.target.value);
          }}
          value={roomCode.toString()}
        />
        <MascotWrapper>
          <MenuButton type="submit">Join room</MenuButton>
          <SpeechTail />
          <StyleYaqoot src="./mascot_waving.png" alt="yaqoot waving" />
        </MascotWrapper>
      </Box>
    </Wrapper>
  );
};

const Wrapper = styled(AnimatedBackground)`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  overflow: hidden;
`;
const LinkWrapper = styled.div`
  position: absolute;
  right: 4%;
  top: 3%;
  text-align: right;

  & > button {
    margin: 4px 0;
  }
`;

const MascotWrapper = styled.div`
  position: relative;
  z-index: 0;
`;

const SpeechTail = styled.div`
  position: absolute;
  width: 0px;
  height: 0px;
  right: 30px;
  border-left: 25px solid transparent;
  border-bottom: 25px solid transparent;
  border-right: 25px solid #000000;
`;

const StyleYaqoot = styled.img`
  height: 60vh;
  position: absolute;
  top: 0;
  left: -30px;
  z-index: -1;
`;
