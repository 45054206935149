import styled from "styled-components";
import { usePresenterStateQuery } from "../store/presenterApi";
import { Chatbox } from "./Chatbox";
import { Small } from "./TextTypes";

const ChatLayout = () => {
  const { data } = usePresenterStateQuery();
  const chatMsgs = data?.chatHistory || [];

  return chatMsgs.length > 0 ? (
    <Wrapper>
      {chatMsgs.map((chatMsg) => (
        <Message key={chatMsg.id} {...chatMsg} />
      ))}
    </Wrapper>
  ) : null;
};

interface MessageProps {
  msg: string;
  playerNick: string;
}

const Message: React.FC<MessageProps> = ({ msg, playerNick }) => (
  <Chatbox>
    <ChatContent>
      <MessageText>
        <Small>{msg}</Small>
      </MessageText>
      <StyledPlayerNick>{playerNick}</StyledPlayerNick>
    </ChatContent>
  </Chatbox>
);

export default ChatLayout;

const Wrapper = styled.div`
  position: absolute;
  top: 40px;
  right: 0;
  max-height: 40vh;
  overflow: hidden;
  width: 240px;
`;

const ChatContent = styled.div`
  padding: 4px 0 0 12px;
`;

const MessageText = styled.div`
  height: 98px;
  overflow: hidden;
`;

const StyledPlayerNick = styled(Small)`
  color: #808080;
  margin: 0;
`;
