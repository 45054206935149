import React from "react";
import styled from "styled-components";
import { AnimatedBackground } from "../Components/AnimatedBackground";
import { MenuButton } from "../Components/Button";
import { ExtraLargeHeading, LargeHeading } from "../Components/TextTypes";
import {
  presenterApi,
  usePresenterStateQuery,
  useSkipQuestionTimerMutation,
} from "../store/presenterApi";
import { useAppDispatch } from "../store/store";
import { colors, AlternativeColors } from "../styles";
import { Answer } from "./Admin";
import { PresenterScreen } from "./ScreenSelector";

const symbol = ["A", "B", "C", "D"];
const alternativeColors: AlternativeColors[] = [
  "red",
  "blue",
  "yellow",
  "green",
];

export const QuestionScreen: React.FC = () => {
  const { data } = usePresenterStateQuery();
  const [dispatchSkipQuestion] = useSkipQuestionTimerMutation();
  const dispatch = useAppDispatch();

  if (!data) return null;

  const { currentQuestion, playerCode, finished } = data;
  const { question, correctAnswer, remainingTime, alternatives } =
    currentQuestion;

  const handleSkipQuestionTimer = () => {
    if (!playerCode) return;
    dispatchSkipQuestion({ code: playerCode });
  };

  const goToScoreScreen = () => {
    const patch = dispatch(
      presenterApi.util.updateQueryData(
        "presenterState",
        undefined,
        (draft) => {
          draft.room = finished
            ? PresenterScreen.Finished
            : PresenterScreen.Score;
        }
      )
    );
  };

  return (
    <Wrapper>
      <LargeHeading>
        {correctAnswer ? "Time's up!" : `Time ticks: ${remainingTime}`}
      </LargeHeading>
      <ExtraLargeHeading>{question}</ExtraLargeHeading>
      <Alternatives>
        {alternatives?.map((alternative, index) => {
          return (
            <Alternative
              key={index}
              correctAnswer={correctAnswer}
              alternative={alternativeColors[index]}
              answer={index}
            >
              <LargeHeading>
                {symbol[index]}: {alternative}
              </LargeHeading>
            </Alternative>
          );
        })}
      </Alternatives>
      <ButtonContainer>
        {correctAnswer === null ? (
          <MenuButton onClick={handleSkipQuestionTimer}>
            Skip question
          </MenuButton>
        ) : (
          <MenuButton onClick={goToScoreScreen}>Highscore -&gt;</MenuButton>
        )}
      </ButtonContainer>
    </Wrapper>
  );
};

const Wrapper = styled(AnimatedBackground)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  min-height: 100vh;
`;

const ButtonContainer = styled.div`
  position: fixed;
  left: 0;
  top: 0;
`;

interface AltProps {
  alternative: AlternativeColors;
  correctAnswer: Answer | null;
  answer: number;
}

const Alternative = styled.div<AltProps>`
  width: 45%;
  background-color: ${(props) => colors[props.alternative]};
  opacity: ${(props) => {
    const { correctAnswer, answer } = props;
    if (correctAnswer === null) {
      return 1;
    } else if (correctAnswer === answer) {
      return 1;
    } else {
      return 0.4;
    }
  }};
  margin: 24px;
  border-radius: 3px;
`;

const Alternatives = styled.div`
  width: 80vw;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
`;
