import { configureStore } from "@reduxjs/toolkit";
import { playerApi } from "./playerApi";
import { presenterApi } from "./presenterApi";
import { useDispatch } from "react-redux";

export const store = configureStore({
  reducer: {
    [playerApi.reducerPath]: playerApi.reducer,
    [presenterApi.reducerPath]: presenterApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      playerApi.middleware,
      presenterApi.middleware
    ),
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
