import React from "react";
import styled from "styled-components";
import { AnswerButton } from "../Components/Button";
import {
  playerApi,
  useGetStateQuery,
  useSubmitAnswerMutation,
} from "../store/playerApi";
import { useAppDispatch } from "../store/store";
import { PlayerScreen, popcat } from "./ScreenSelector";

function trackEvent(value: number, eventType: string) {
  type WindowPossiblyWithUmami =
    | (Window & { umami?: { trackEvent: Function } })
    | undefined;

  const colorByNumber = {
    0: "red",
    1: "blue",
    2: "yellow",
    3: "green",
  };

  (window as WindowPossiblyWithUmami)?.umami?.trackEvent(
    colorByNumber[value as 0 | 1 | 2 | 3],
    eventType
  );
}

export const Alternatives = () => {
  const { data } = useGetStateQuery();
  const [dispatchAnswer] = useSubmitAnswerMutation();
  const dispatch = useAppDispatch();
  const gameState = data?.currentQuestion;

  if (!gameState) {
    console.log("Error no Gamestate");
    return null;
  }

  const { remainingTimeToAnswer } = gameState;

  const submitAnswer = (answer: 0 | 1 | 2 | 3) => {
    popcat.play();
    trackEvent(answer, "answer");
    dispatchAnswer({ answer });

    // Update gameState
    dispatch(
      playerApi.util.updateQueryData("getState", undefined, (draft) => {
        if (draft.currentQuestion === null) {
          console.log("Error, currentQuestion should not be null");
          return;
        }
        draft.currentQuestion.playerAnswer = answer;
        draft.previousAnswers.push(answer);
        draft.screen = PlayerScreen.Waiting;
      })
    );
  };

  return (
    <>
      <H3>Time remaining: {remainingTimeToAnswer}</H3>
      <div>
        <ButtonGroup>
          <AnswerButton color="red" onClick={() => submitAnswer(0)}>
            A
          </AnswerButton>
          <AnswerButton color="blue" onClick={() => submitAnswer(1)}>
            B
          </AnswerButton>
        </ButtonGroup>
        <ButtonGroup>
          <AnswerButton color="yellow" onClick={() => submitAnswer(2)}>
            C
          </AnswerButton>
          <AnswerButton color="green" onClick={() => submitAnswer(3)}>
            D
          </AnswerButton>
        </ButtonGroup>
      </div>
    </>
  );
};

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
`;

const H3 = styled.h3`
  color: white;
`;
