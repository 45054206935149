import React from "react";
import styled from "styled-components";
import { GameScreen } from "../Components/AnimatedBackground";
import IdleTextInput from "../Components/IdleTextInput";
import { LargeHeading, SmallHeading } from "../Components/TextTypes";
import { useGetStateQuery } from "../store/playerApi";
import { colors } from "../styles";

const formatPlacement = (placement: number) => {
  const placementString = placement.toString();
  if (placementString.at(-1) === "1") {
    return placement + "st";
  }
  if (placementString.at(-1) === "2") {
    return placement + "nd";
  }
  if (placementString.at(-1) === "3") {
    return placement + "rd";
  }
  return placement + "th";
};

export const AnswerScreen: React.FC = () => {
  const { data } = useGetStateQuery();

  if (!data) return null;

  const { id, currentQuestion, allHighscores, finished } = data;
  const correct =
    currentQuestion?.playerAnswer !== null &&
    currentQuestion?.correctAnswer === currentQuestion?.playerAnswer;

  const latestHighscore = [...allHighscores].at(-1) ?? [];
  const sorted = [...latestHighscore]
    .sort((a, b) => a.score - b.score)
    .reverse();

  const playerPlacement = sorted.findIndex((user) => user.socket_id === id) + 1;

  const calculateScoreDiff = () => {
    const currenstScore = latestHighscore.find(
      (user) => user.socket_id === id
    )?.score;
    const previousHighscore = allHighscores.at(-2) ?? [];
    const previousScore = previousHighscore.find(
      (user) => user.socket_id === id
    )?.score;

    if (currenstScore && previousScore) {
      return currenstScore - previousScore;
    } else if (currenstScore && !previousScore) {
      return currenstScore;
    } else {
      return 0;
    }
  };

  return (
    <AnswerWrapper correct={correct}>
      <Wrapper>
        <Flex>
          <LargeHeading>{correct ? "correct!" : "wrong!"} </LargeHeading>
          <AnswerIcon correct={correct}>
            <LargeHeading>🎉</LargeHeading>
          </AnswerIcon>
        </Flex>

        <ScoreBox>
          <div style={{ opacity: 1 }}>
            {correct ? `+${calculateScoreDiff()} points` : ":("}
          </div>
        </ScoreBox>

        <BottomContent>
          <SmallHeading>
            {finished ? "You placed" : "You are in"}{" "}
            {formatPlacement(playerPlacement)}
          </SmallHeading>
          <IdleTextInput />
        </BottomContent>
      </Wrapper>
    </AnswerWrapper>
  );
};

const Flex = styled.div`
  display: flex;
`;

const AnswerIcon = styled.div<{ correct: boolean }>`
  margin-left: "4px";
  transform: rotate(${({ correct }) => (correct ? "0deg" : "180deg")});
`;

const ScoreBox = styled.div`
  background-color: rgba(255, 255, 255, 0.6);
  padding: 20px 0px;
  text-align: center;
  width: 100%;
  max-width: 300px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 80vh;
`;

const BottomContent = styled.div`
  justify-content: flex-end;
  padding-bottom: 20px;
`;

interface AnswerWrapperProps {
  correct: boolean;
}

const AnswerWrapper = styled(GameScreen)`
  background-color: ${(props: AnswerWrapperProps) =>
    props.correct ? colors.green : colors.red};
  display: flex;
  align-items: center;
  justify-content: center;
`;
