import styled, { keyframes, css } from "styled-components";
import { colors } from "../styles";

const colorTransition = keyframes`
  0% {
    background-color: ${colors.yellow};
  } 
  13%{
    background-color: #ff94b9;
  }
  25% {
    background-color: ${colors.blue};
  }
  38%{
    background-color: #00cddf;
  }
  50% {
    background-color: ${colors.green};
  }
  63%{
    background-color: #e0aa45;
  }
  75% {
    background-color: ${colors.red};
  }
  88%{
    background-color: #ffa465;
  }
  100%{
    background-color: ${colors.yellow}
  }
`;

interface AnimationProps {
  animate?: boolean;
}

export const AnimatedBackground = styled.div`
  width: 100%;
  min-height: 100vh;
  ${(props: AnimationProps) =>
    props.animate
      ? css`
          animation: 20s ${colorTransition} ease-in-out infinite;
        `
      : css`
          background-color: ${colors.white};
        `}
`;

export const GameScreen = styled.div`
  width: 100%;
  min-height: 100vh;
`;
