import styled from "styled-components";
import { useTransition, animated, config, useSpring } from "react-spring";
import { scaleLinear } from "d3-scale";
import { JumboHeading } from "../../Components/TextTypes";
import { MenuButton } from "../../Components/Button";
import { AnimatedBackground } from "../../Components/AnimatedBackground";
import {
  useNextQuestionMutation,
  usePresenterStateQuery,
} from "../../store/presenterApi";
import { popcat } from "../ScreenSelector";

type ScoreListViz = {
  currentScore: number;
  previousScore: number;
  nick: string;
  socket_id: string;
}[];

export const HighscoreScreen = () => {
  const { data } = usePresenterStateQuery();
  const [dispatchNextQuestion] = useNextQuestionMutation();

  const allHighscores = data?.allHighscores ?? [];

  const currentHighscore = allHighscores.at(-1) ?? [];
  const previousHighscore = allHighscores.at(-2) ?? [];

  // Find highest score to use as max
  const highestScore = Math.max(
    ...currentHighscore.map((score) => score.score)
  );

  // Map score to a screenWidth value
  const scale = scaleLinear([0, highestScore], [200, window.innerWidth - 300]);

  const vizList: ScoreListViz = currentHighscore.map((user, i) => ({
    currentScore: user.score,
    previousScore:
      previousHighscore.find((pUser) => pUser.socket_id === user.socket_id)
        ?.score ?? 0,
    nick: user.nick,
    socket_id: user.socket_id,
  }));

  const barHeight = 65;
  const oldPos = [...previousHighscore]
    .sort((a, b) => a.score - b.score)
    .reverse()
    .map((d) => d.socket_id);
  const newPos = [...currentHighscore]
    .sort((a, b) => a.score - b.score)
    .reverse()
    .map((d) => d.socket_id);

  const handleNextQuestionNavigation = () => {
    popcat.play();
    const code = data?.playerCode;
    if (!code) {
      console.error("Missing code!");
      return;
    }
    dispatchNextQuestion({ code });
  };

  const transitions = useTransition(vizList, {
    keys: (d) => d.socket_id,
    from: (d, i) => ({
      width: scale(d.previousScore),
      y: oldPos.indexOf(d.socket_id) * barHeight,
    }),
    enter: (d, i) => [
      {
        width: scale(d.currentScore),
      },
      { y: newPos.indexOf(d.socket_id) * barHeight },
    ],
    update: (d, i) => [
      {
        width: scale(d.currentScore),
      },
      { y: newPos.indexOf(d.socket_id) * barHeight },
    ],
    leave: (d, i) => [{ y: 10000 }, { width: 0 }],
    config: { ...config.molasses, duration: 500 },
    delay: 500,
  });

  return (
    <Wrapper animate>
      <JumboHeading>Highscore</JumboHeading>
      <StyleYaqoot src="./mascot_jumping.png" alt="yaqoot jumping" />
      <HighscoreBox>
        {transitions((style, item, t, index) => (
          <animated.div key={index} style={{ ...style, position: "absolute" }}>
            <Bar {...item} />
          </animated.div>
        ))}
      </HighscoreBox>
      <FixedContainer>
        <MenuButton onClick={handleNextQuestionNavigation}>
          Next Question
        </MenuButton>
      </FixedContainer>
    </Wrapper>
  );
};

interface BarProps {
  currentScore: number;
  previousScore: number;
  nick: string;
}

const Bar: React.FC<BarProps> = (props) => {
  const { currentScore, previousScore, nick } = props;

  const springProps = useSpring({
    from: { width: previousScore },
    to: { width: currentScore },
    delay: 500,
  });

  return (
    <HighscoreBar>
      <span>{nick.length > 20 ? nick.slice(0, 20) + "..." : nick} </span>
      <span>
        <animated.span>
          {springProps.width.to((x) => x.toFixed(0))}
        </animated.span>{" "}
        P
      </span>
    </HighscoreBar>
  );
};

const StyleYaqoot = styled.img`
  height: 350px;
  position: absolute;
  top: -50px;
  left: -150px;
  transform: rotate(45deg);
`;

const FixedContainer = styled.div`
  position: fixed;
  right: 10;
`;

const HighscoreBox = styled.div`
  margin: 0 auto;
  width: 90%;
  height: 80vh;
  background-color: rgba(255, 255, 255, 0.6);
  padding: 24px;
  overflow-y: scroll;
  position: relative;
`;

const Wrapper = styled(AnimatedBackground)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  min-height: 100vh;
`;

const HighscoreBar = styled.div`
  background-color: #99d367;
  padding: 20px 20px 20px 20px;
  text-align: left;
  display: flex;
  justify-content: space-between;
`;
