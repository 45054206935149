import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  backendUrl,
  getSocket,
  PresenterAction,
  ServerMessages,
} from "../context/socket";
import { Answer, PresenterState } from "../Screens/Admin";
import { PresenterScreen } from "../Screens/ScreenSelector";
import { ChatMessage, PresenterGameStatus } from "../types";

export const presenterApi = createApi({
  reducerPath: "presenter",
  baseQuery: fetchBaseQuery({ baseUrl: backendUrl() }),
  endpoints: (builder) => ({
    submitRoomCode: builder.mutation<void, { code: string }>({
      queryFn: ({ code }) => {
        const socket = getSocket();
        return new Promise((resolve) => {
          socket.emit(PresenterAction.SubmitRoomCode, { code }, (res: any) =>
            resolve({ data: res })
          );
        });
      },
    }),
    panik: builder.mutation<void, { code: string }>({
      queryFn: ({ code }) => {
        const socket = getSocket();
        return new Promise((resolve) => {
          socket.emit(PresenterAction.Reset, { code }, (res: any) =>
            resolve({ data: res })
          );
        });
      },
    }),
    nextQuestion: builder.mutation<void, { code: string }>({
      queryFn: ({ code }) => {
        const socket = getSocket();
        return new Promise((resolve) => {
          socket.emit(PresenterAction.NextQuestion, { code }, (res: any) =>
            resolve({ data: res })
          );
        });
      },
    }),
    skipQuestionTimer: builder.mutation<void, { code: string }>({
      queryFn: ({ code }) => {
        const socket = getSocket();
        return new Promise((resolve) => {
          socket.emit(PresenterAction.SkipTimer, { code }, (res: any) =>
            resolve({ data: res })
          );
        });
      },
    }),
    presenterState: builder.query<PresenterState, void>({
      queryFn: () => ({
        data: {
          currentQuestion: {
            remainingTime: null,
            alternatives: null,
            question: null,
            correctAnswer: null,
          },
          allHighscores: [],
          chatHistory: [],
          roomPasscode: null,
          room: PresenterScreen.Code,
          roomTitle: null,
          playerCode: null,
          finished: false,
          id: null,
        },
      }),
      async onCacheEntryAdded(
        arg,
        { cacheDataLoaded, cacheEntryRemoved, updateCachedData }
      ) {
        try {
          await cacheDataLoaded;
          const socket = getSocket();

          socket.on(ServerMessages.RoomYouControl, (data: { room: number }) => {
            const { room } = data;
            updateCachedData((draft) => {
              draft.roomPasscode = room;
              draft.room = PresenterScreen.Lobby;
            });
          });

          socket.on(
            ServerMessages.ShowChat,
            (data: { newMessage: string; playerNick: string; id: string }) => {
              updateCachedData((draft) => {
                const { playerNick, id, newMessage } = data;
                const msg: ChatMessage = { msg: newMessage, playerNick, id };
                draft.chatHistory = [...draft.chatHistory, msg];
              });
            }
          );

          // Something bad happend, we panik
          socket.on(ServerMessages.Reset, () => {
            window.location.reload();
          });

          socket.on(
            ServerMessages.Question,
            (data: {
              question: string;
              answer: Answer;
              alternatives: [string, string, string, string];
            }) => {
              const { question, alternatives } = data;
              updateCachedData((draft) => {
                draft.currentQuestion.question = question;
                draft.currentQuestion.alternatives = alternatives;
                draft.currentQuestion.correctAnswer = null;
                draft.room = PresenterScreen.Question;
              });
            }
          );

          socket.on(ServerMessages.Tick, (data: { time: number }) => {
            updateCachedData((draft) => {
              draft.currentQuestion.remainingTime = data.time;
            });
          });

          socket.on(ServerMessages.Highscore, (data: PresenterGameStatus) => {
            console.log("Higscore data!", data);
            const { status, highscore, answer } = data;
            updateCachedData((draft) => {
              if (status === "user joined") {
                draft.allHighscores = [highscore]; // TODO: handle players joining in the middle of the game
              } else if (status === "start") {
                draft.room = PresenterScreen.Question;
              } else if (status === "between question") {
                draft.currentQuestion.correctAnswer = answer;
                draft.allHighscores.push(highscore);
              } else if (status === "finished") {
                draft.finished = true;
                draft.allHighscores.push(highscore);
                draft.currentQuestion.correctAnswer = answer;
              }
            });
          });

          // Transition player to set nick

          await cacheEntryRemoved;
        } catch (error) {}
      },
    }),
  }),
});

export const {
  usePresenterStateQuery,
  usePanikMutation,
  useNextQuestionMutation,
  useSkipQuestionTimerMutation,
  useSubmitRoomCodeMutation,
} = presenterApi;
