import styled from "styled-components";
import { popcat } from "../Screens/ScreenSelector";
import {
  usePanikMutation,
  usePresenterStateQuery,
} from "../store/presenterApi";
import { Link } from "./Link";

type Props = {
  audioRef: React.RefObject<HTMLAudioElement>;
};

export const GameController: React.FC<Props> = ({ audioRef }) => {
  const { data } = usePresenterStateQuery();
  const [panik] = usePanikMutation();

  if (!data) return null;
  const { playerCode } = data;

  const handleReset = () => {
    popcat.play();
    if (!playerCode) return;
    panik({ code: "42" });
  };

  return (
    <Container>
      <audio ref={audioRef} controls src="/yaqoot.mp3" loop />
      <StyledLink onClick={handleReset}>Reboot room ♻</StyledLink>
    </Container>
  );
};

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  margin-left: 20px;
`;

const Container = styled.div`
  padding: 8px;
  width: 100%;
  display: flex;
  justify-content: center;
`;
