import { RouteComponentProps } from "@reach/router";
import { useGetStateQuery } from "../store/playerApi";
import { Alternatives } from "./Alternatives";
import { AnswerScreen } from "./AnswerScreen";
import { JoinRoom } from "./JoinRoom";
import { Highscore } from "../types";
import { SetNick } from "./SetNick";
import { Waiting } from "./Waiting";

export enum PlayerScreen {
  Join = "joinroom",
  SetNick = "setnick",
  Waiting = "waiting",
  Question = "question",
  Answer = "answer",
  Final = "final",
}
// "code" | "lobby" | "question" | "score" | "finished"
export enum PresenterScreen {
  Code = "code",
  Lobby = "lobby",
  Question = "question",
  Score = "score",
  Finished = "finished"
}

export enum GameStatus {
  NotStarted = "not started",
  BetweenQuestion = "between question",
  Answer = "answer",
  Question = "question",
  Finished = "finished",
  Start = "start",
}

export type RoomName = "joinroom" | "setnick" | "lobby" | "playing";

export type Alert = {
  severity: "info" | "warning" | "success" | "error";
  text: string;
};

export const popcat = new Audio("/popcat.mp3");


export type PlayerGameState = {
  screen: PlayerScreen;
  nick: string | null;
  roomName: string | null;
  alert: Alert | null;
  current: number | null;
  id: string | null;
  gameStatus: GameStatus;
  currentQuestion: {
    playerAnswer: 0 | 1 | 2 | 3 | null; // Null if player has yet to answer
    correctAnswer: 0 | 1 | 2 | 3 | null; // Null until server reveals answer
    remainingTimeToAnswer: number;
  };
  finished: boolean;
  score: number;
  previousAnswers: number[];
  previousCorrectAnswers: number[];
  allHighscores: Highscore[];
};

export const ScreenSelector = (props: RouteComponentProps) => {
  const { data } = useGetStateQuery();

  switch (data?.screen) {
    case PlayerScreen.Join:
      return <JoinRoom />;
    case PlayerScreen.SetNick:
      return <SetNick />;
    case PlayerScreen.Waiting:
      return <Waiting />;
    case PlayerScreen.Question:
      return <Alternatives />;
    case PlayerScreen.Answer:
      return <AnswerScreen />;
    default:
      return <p>I did a bad</p>;
  }
};
