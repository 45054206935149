import { useEffect } from "react";

type WindowPossiblyWithUmami = Window & {umami?: {trackView: Function}} | undefined

function usePageTracker(route: string): void {
    const windowPossiblyWithUmami = window as WindowPossiblyWithUmami;

    useEffect(() => {
        windowPossiblyWithUmami?.umami?.trackView(route);
    }, [route, windowPossiblyWithUmami?.umami]);
}

export default usePageTracker;