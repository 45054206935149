import React, { FormEvent, useState } from "react";
import styled from "styled-components";
import { AnimatedBackground } from "../Components/AnimatedBackground";
import { Box } from "../Components/Box";
import { MenuButton } from "../Components/Button";
import { Input } from "../Components/Input";
import { MediumHeading, SmallHeading } from "../Components/TextTypes";
import {
  playerApi,
  useGetStateQuery,
  useSetNickMutation,
} from "../store/playerApi";
import { useAppDispatch } from "../store/store";
import { popcat } from "./ScreenSelector";

export const SetNick: React.FC = () => {
  const [nick, setNick] = useState("");
  const { data } = useGetStateQuery();
  const [dispatchNick] = useSetNickMutation();
  const dispatch = useAppDispatch();
  const emitNick = (e: FormEvent<HTMLFormElement>) => {
    popcat.play();
    e.preventDefault();

    // Update state locally with nick
    const patch = dispatch(
      playerApi.util.updateQueryData("getState", undefined, (draft) => {
        draft.nick = nick;
      })
    );

    dispatchNick({ nick });
  };

  return (
    <Wrapper animate>
      <WidthWrapper>
        <SmallHeading>You joined</SmallHeading>
        <MediumHeading>{data?.roomName}</MediumHeading>
        <Box onFormSubmit={emitNick}>
          <Input
            placeholder="Write your cool nickname"
            onChange={(e) => setNick(e.target.value)}
            value={nick.toString()}
          />
          <MenuButton type="submit">Join lobby</MenuButton>
        </Box>
      </WidthWrapper>
    </Wrapper>
  );
};

const Wrapper = styled(AnimatedBackground)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
`;

const WidthWrapper = styled.div`
  width: 80%;
`;
