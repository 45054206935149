import styled, { css } from "styled-components";
import { AnimatedBackground } from "../../Components/AnimatedBackground";
import { MenuButton } from "../../Components/Button";
import { Normal, JumboHeading } from "../../Components/TextTypes";
import { colors } from "../../styles";
import { useSpring, animated } from "react-spring";
import {
  usePanikMutation,
  usePresenterStateQuery,
} from "../../store/presenterApi";
import { popcat } from "../ScreenSelector";
import { User } from "../../types";

interface TopThree {
  idx: 0 | 1 | 2;
  text: "1st" | "2nd" | "3rd";
  theme: ThemeKeys;
  emoji: string;
}

const DURATION = 1500;

export const Finished = () => {
  const { data } = usePresenterStateQuery();
  const [dispatchResetRoom] = usePanikMutation();

  const lastHighscore = data?.allHighscores.at(-1) ?? [];

  const sorted = [...lastHighscore].sort((a, b) => a.score - b.score).reverse();

  const placement: TopThree[] = [
    { idx: 1, text: "2nd", theme: "second", emoji: "💙" },
    { idx: 0, text: "1st", theme: "first", emoji: "⭐" },
    { idx: 2, text: "3rd", theme: "third", emoji: "🚀" },
  ];

  const handleRestRoom = () => {
    popcat.play();
    const code = data?.playerCode;
    if (!code) {
      console.error("Missing code!");
      return;
    }
    dispatchResetRoom({ code });
    window.location.reload();
  };

  return (
    <AnimatedBackground animate>
      <StyleYaqoot src="./mascot_jumping.png" alt="yaqoot jumping" />
      <Container>
        <JumboHeading>FINAL SCORES</JumboHeading>
      </Container>
      <HighscoreBoxLastScreen>
        <VerticalBars>
          {sorted.length === 1 && (
            <VerticalBar placement={placement[1]} user={sorted[0]} />
          )}
          {sorted.length === 2 && (
            <>
              <VerticalBar placement={placement[0]} user={sorted[1]} />
              <VerticalBar placement={placement[1]} user={sorted[0]} />
            </>
          )}
          {sorted.length >= 3 &&
            placement.map((p) => (
              <VerticalBar key={p.idx} placement={p} user={sorted[p.idx]} />
            ))}
        </VerticalBars>
      </HighscoreBoxLastScreen>
      <div style={{ position: "fixed", right: 20, top: "50%" }}>
        <MenuButton onClick={handleRestRoom}>End game</MenuButton>
      </div>
    </AnimatedBackground>
  );
};

interface BarProps {
  user: User;
  placement: TopThree;
}

const VerticalBar = (props: BarProps) => {
  const { score, nick } = props.user;
  const { idx, text, theme, emoji } = props.placement;

  const counterStyle = useSpring({
    from: { width: 0 },
    to: { width: score },
    delay: (2 - idx) * DURATION + 500,
  });

  const { x } = useSpring({
    from: { x: 0 },
    to: { x: 1 },
    delay: (2 - idx) * DURATION + 1000,
  });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <animated.span
        style={{
          opacity: x.to({ range: [0, 1], output: [0, 1] }),
          scale: x.to({
            range: [0, 0.5, 0.8, 1],
            output: [1, 1.5, 0.8, 1],
          }),
          fontSize: "24px",
        }}
      >
        {emoji} {nick} {emoji}
      </animated.span>
      <StyledVerticalBar themeKey={theme}>
        <Normal>
          <animated.span>
            {counterStyle.width.to((x) => x.toFixed(0))}
          </animated.span>
          p
        </Normal>
      </StyledVerticalBar>
      <Normal>{text}</Normal>
    </div>
  );
};

const StyleYaqoot = styled.img`
  height: 350px;
  position: absolute;
  top: -50px;
  left: -150px;
  transform: rotate(45deg);
`;

const HighscoreBoxLastScreen = styled.div`
  margin: 0 auto;
  width: 90%;
  height: 70vh;
  background-color: rgba(255, 255, 255, 0.6);
  padding: 24px;
  overflow-y: auto;
`;

export const HighscoreBar = styled.div`
  background-color: #99d367;
  padding: 20px 20px 20px 20px;
  margin: 20px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  width: 50%;
  ${(props: { index: number }) => props.index === 0 && "width: 80%;"}
  ${(props: { index: number }) => props.index === 1 && "width: 75%"}
  max-width: 1200px;
`;

type Theme = {
  bg: string;
  fontSize: string;
  width: string;
  height: string;
};

type ThemeKeys = "first" | "second" | "third";
type Themes = {
  [key in ThemeKeys]: Theme;
};

const theme: Themes = {
  first: {
    bg: colors.yellow,
    fontSize: "42px",
    width: "400px",
    height: "600px",
  },
  second: {
    bg: colors.blue,
    fontSize: "38px",
    width: "350px",
    height: "400px",
  },
  third: {
    bg: colors.red,
    fontSize: "32px",
    width: "300px",
    height: "200px",
  },
};

interface StyledProps {
  themeKey: ThemeKeys;
}

const VerticalBars = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledVerticalBar = styled.div`
  ${({ themeKey }: StyledProps) => css`
    height: ${theme[themeKey].height};
    width: ${theme[themeKey].width};
    font-size: ${theme[themeKey].fontSize};
    background: ${theme[themeKey].bg};
  `}
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 12px;
`;
