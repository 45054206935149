import { useState } from "react";
import styled from "styled-components";
import { popcat } from "../Screens/ScreenSelector";
import {
  useGetStateQuery,
  useSendChatMessageMutation,
} from "../store/playerApi";
import { MenuButton } from "./Button";
import { Chatbox } from "./Chatbox";

const IdleTextInput: React.FC = () => {
  const { data } = useGetStateQuery();

  const nick = data?.nick ?? "Player";
  const [dispatchChatMessage] = useSendChatMessageMutation();

  const [textMsg, setTextMsg] = useState("");
  const sendMessage = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    popcat.play();
    if (textMsg.trim().length > 0) {
      dispatchChatMessage({ textMsg, playerNick: nick });
      setTextMsg("");
    }
  };

  return (
    <form onSubmit={sendMessage}>
      <Chatbox>
        <StyledInput
          value={textMsg}
          placeholder="Write a message for the big screen"
          onChange={(e) => {
            if (e.target.value.length > 100) {
              return;
            }
            setTextMsg(e.target.value);
          }}
        />
      </Chatbox>
      <div style={{ marginBottom: "20px" }}></div>
      <MenuButton type="submit">Send Message</MenuButton>
    </form>
  );
};

const StyledInput = styled.textarea`
  border: none;
  min-height: 100px;
  width: 90%;
  resize: none;
`;

export default IdleTextInput;
