import { useRef } from "react";
import { RouteComponentProps } from "@reach/router";
import styled from "styled-components";
import { ChatHistory, Highscore } from "../types";
import ChatLayout from "../Components/ChatLayout";
import { PresenterScreen } from "./ScreenSelector";
import { QuestionScreen } from "./QuestionScreen";
import { HighscoreScreen } from "./Quizmaster/Highscores";
import { Finished } from "./Quizmaster/Finished";
import { usePresenterStateQuery } from "../store/presenterApi";
import { Code } from "./Quizmaster/Code";
import { Lobby } from "./Quizmaster/Lobby";
import { GameController } from "../Components/PresenterGameControls";

export type PresenterState = {
  currentQuestion: {
    remainingTime: number | null;
    alternatives: [string, string, string, string] | null;
    question: string | null;
    correctAnswer: Answer;
  };
  id: string | null;
  allHighscores: Highscore[];
  roomPasscode: number | null;
  chatHistory: ChatHistory;
  room: PresenterScreen;
  roomTitle: string | null;
  playerCode: string | null;
  finished: boolean;
};

export type Answer = 0 | 1 | 2 | 3 | null;

export const Admin = (props: RouteComponentProps) => {
  const { data } = usePresenterStateQuery();

  const audioRef = useRef<HTMLAudioElement>(null);

  if (!data) return null;

  const { room } = data;

  const screenSelector = () => {
    switch (room) {
      case PresenterScreen.Code:
        return <Code />;
      case PresenterScreen.Lobby:
        return <Lobby />;
      case PresenterScreen.Question:
        return <QuestionScreen />;
      case PresenterScreen.Score:
        return <HighscoreScreen />;
      case PresenterScreen.Finished:
        return <Finished />;
      default:
        console.log(`Do i look like i know what a ${room} is?`);
        return <div>Error</div>;
    }
  };

  return (
    <AdminWrapper>
      <ChatLayout />
      {screenSelector()}
      {room !== PresenterScreen.Code && <GameController audioRef={audioRef} />}
    </AdminWrapper>
  );
};

const AdminWrapper = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
`;

export const PlayerName = styled.h3`
  display: flex;
  align-items: center;
  font-size: 28px;
  margin: 0px;
  padding: 5px 0px;
`;

export const StyleYaqootCode = styled.img`
  height: 30vh;
  position: absolute;
  bottom: 0;
  right: 80px;
`;
