import React from "react";

export const Logo = () => {
  return (
    <svg
      width="288"
      height="124"
      viewBox="0 0 288 124"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.176 111C32.2987 111 30.848 110.531 29.824 109.592C28.8853 108.568 28.416 107.117 28.416 105.24V66.9679L1.152 23.1919C0.384 21.9119 0 20.7599 0 19.7359C0 18.7119 0.426667 17.9012 1.28 17.3039C2.21867 16.6212 3.456 16.2799 4.992 16.2799H20.224C23.2107 16.2799 25.5147 17.5599 27.136 20.1199L40.448 41.4959L53.76 20.1199C55.3813 17.5599 57.6853 16.2799 60.672 16.2799H75.904C77.44 16.2799 78.6347 16.6212 79.488 17.3039C80.4267 17.9012 80.896 18.7119 80.896 19.7359C80.896 20.7599 80.512 21.9119 79.744 23.1919L52.608 66.9679V105.24C52.608 107.117 52.096 108.568 51.072 109.592C50.1333 110.531 48.7253 111 46.848 111H34.176Z"
        fill="black"
      />
      <path
        d="M82.855 111C81.2337 111 79.9537 110.659 79.015 109.976C78.0763 109.208 77.607 108.184 77.607 106.904C77.607 106.392 77.7777 105.539 78.119 104.344L107.431 20.8879C108.626 17.8159 110.802 16.2799 113.959 16.2799H128.551C131.879 16.2799 134.098 17.8159 135.207 20.8879L164.391 104.344C164.732 105.539 164.903 106.392 164.903 106.904C164.903 108.184 164.434 109.208 163.495 109.976C162.556 110.659 161.276 111 159.655 111H145.959C142.631 111 140.455 109.421 139.431 106.264L135.079 93.4639H107.815L103.335 106.264C102.226 109.421 100.007 111 96.679 111H82.855ZM130.599 73.6239L121.255 47.3839L112.807 73.6239H130.599Z"
        fill="black"
      />
      <path
        d="M256.302 122.392C255.022 123.416 253.742 123.928 252.462 123.928C250.926 123.928 249.518 123.117 248.238 121.496L239.278 109.976C233.561 112.195 227.502 113.304 221.102 113.304C212.057 113.304 203.737 111.085 196.142 106.648C188.547 102.211 182.489 96.1946 177.966 88.5999C173.529 80.9199 171.31 72.5999 171.31 63.6399C171.31 54.5946 173.529 46.2746 177.966 38.6799C182.489 31.0852 188.547 25.0692 196.142 20.6319C203.737 16.1092 212.057 13.8479 221.102 13.8479C230.062 13.8479 238.339 16.1092 245.934 20.6319C253.614 25.0692 259.673 31.0852 264.11 38.6799C268.633 46.2746 270.894 54.5946 270.894 63.6399C270.894 69.6986 269.827 75.5012 267.694 81.0479C265.646 86.5092 262.745 91.4586 258.99 95.8959L267.31 106.52C268.334 107.8 268.846 109.08 268.846 110.36C268.846 111.981 267.993 113.432 266.286 114.712L256.302 122.392ZM221.102 89.1119C221.87 89.1119 222.467 89.0692 222.894 88.9839L217.518 82.0719C216.494 80.6212 215.982 79.2986 215.982 78.1039C215.982 76.5679 216.793 75.1599 218.414 73.8799L228.398 66.0719C229.763 65.1332 231.043 64.6639 232.238 64.6639C233.859 64.6639 235.31 65.4746 236.59 67.0959L243.374 75.9279C245.507 72.1732 246.574 68.0772 246.574 63.6399C246.574 59.0319 245.422 54.7652 243.118 50.8399C240.814 46.9146 237.699 43.7999 233.774 41.4959C229.934 39.1919 225.71 38.0399 221.102 38.0399C216.494 38.0399 212.227 39.1919 208.302 41.4959C204.377 43.7999 201.262 46.9146 198.958 50.8399C196.654 54.7652 195.502 59.0319 195.502 63.6399C195.502 68.2479 196.654 72.5146 198.958 76.4399C201.262 80.2799 204.377 83.3519 208.302 85.6559C212.227 87.9599 216.494 89.1119 221.102 89.1119Z"
        fill="black"
      />
      <path
        d="M184.42 19.5328L175.904 30.2446C175.525 30.7219 174.948 31 174.339 31H156C154.895 31 154 30.1046 154 29V5.72947C154 3.83587 156.387 3.00259 157.566 4.48484L166.349 15.5328C166.728 16.01 167.305 16.2881 167.914 16.2881H182.855C184.529 16.2881 185.462 18.2223 184.42 19.5328Z"
        fill="black"
      />
      <path
        d="M257.58 19.5328L266.096 30.2446C266.475 30.7219 267.052 31 267.661 31H286C287.105 31 288 30.1046 288 29V5.72947C288 3.83587 285.613 3.00259 284.434 4.48484L275.651 15.5328C275.272 16.01 274.695 16.2881 274.086 16.2881H259.145C257.471 16.2881 256.538 18.2223 257.58 19.5328Z"
        fill="black"
      />
    </svg>
  );
};
