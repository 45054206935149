import { ScreenSelector } from "./Screens/ScreenSelector";
import { Router } from "@reach/router";
import { Admin } from "./Screens/Admin";

function App() {
  return (
    <div className="App">
      <Router>
        <ScreenSelector path="/" />
        <Admin path="/admin" />
      </Router>
    </div>
  );
}

export default App;
