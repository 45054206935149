import { FormEventHandler } from "react";
import styled, { css } from "styled-components";
import { colors } from "../styles";

interface BoxProps {
  transparent?: boolean;
}

const StyledBox = styled.div`
  ${(props: BoxProps) =>
    props.transparent
      ? css``
      : css`
          border: 2px solid ${colors.blackish};
          border-radius: 10px;
          background-color: ${colors.white};
        `};
`;

const InnerBox = styled.div`
  margin: 24px 36px;
`;

const InnerForm = styled.form`
  margin: 24px 36px;
`;

interface Props {
  onFormSubmit?: FormEventHandler<HTMLFormElement>;
  transparent?: boolean;
  children?: React.ReactNode;
}

export const Box: React.FC<Props> = ({
  children,
  onFormSubmit,
  transparent,
}) => (
  <StyledBox transparent={transparent}>
    {onFormSubmit ? (
      <InnerForm onSubmit={onFormSubmit}>{children}</InnerForm>
    ) : (
      <InnerBox>{children}</InnerBox>
    )}
  </StyledBox>
);
