import IdleTextInput from "../Components/IdleTextInput";
import { SmallHeading } from "../Components/TextTypes";
import usePageTracker from "../hooks/usePageTracker";
import styled from "styled-components";
import { AnimatedBackground } from "../Components/AnimatedBackground";

export const Waiting: React.FC = () => {
  usePageTracker("waiting");
  
  return (
    <Wrapper animate>
      <SmallHeading>Waiting for the quizmaster...</SmallHeading>
      <IdleTextInput />
      <StyleYaqoot src="./mascot_thinking.png" alt="yaqoot thinking" />
    </Wrapper>
  );
};

const Wrapper = styled(AnimatedBackground)`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  height: 100vh;
`;

const StyleYaqoot = styled.img`
  margin-top: 5vh;
  height: 35vh;
  justify-item: flex-end;
`;
