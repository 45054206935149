import { navigate } from "@reach/router";
import React, { useState } from "react";
import styled from "styled-components";
import { AnimatedBackground } from "../../Components/AnimatedBackground";
import { Box } from "../../Components/Box";
import { MenuButton } from "../../Components/Button";
import { Input } from "../../Components/Input";
import { Link } from "../../Components/Link";
import { JumboHeading } from "../../Components/TextTypes";
import {
  presenterApi,
  useSubmitRoomCodeMutation,
} from "../../store/presenterApi";
import { useAppDispatch } from "../../store/store";
import { StyleYaqootCode } from "../Admin";
import { popcat } from "../ScreenSelector";

export const Code = () => {
  const [roomCode, setRoomCode] = useState<string>("");
  const dispatch = useAppDispatch();
  const [dispatchRoomCode] = useSubmitRoomCodeMutation();

  const handleCodeSubmission = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    popcat.play();
    dispatchRoomCode({ code: roomCode });
    dispatch(
      presenterApi.util.updateQueryData(
        "presenterState",
        undefined,
        (draft) => {
          draft.playerCode = roomCode;
        }
      )
    );
  };

  return (
    <Wrapper animate>
      <LinkWrapper>
        <Link
          onClick={() => {
            navigate("/");
          }}
        >
          Player?
        </Link>
      </LinkWrapper>
      <JumboHeading>
        Welcome <br /> quizmaster
      </JumboHeading>
      <Box onFormSubmit={handleCodeSubmission}>
        <Input
          placeholder={"Super secret admin code"}
          type="password"
          onChange={(e) => {
            setRoomCode(e.target.value);
          }}
          value={roomCode}
        />
        <MenuButton>Enter</MenuButton>
      </Box>
      <StyleYaqootCode src="./mascot_thinking.png" alt="yaqoot thinking" />
    </Wrapper>
  );
};

const Wrapper = styled(AnimatedBackground)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  min-height: 100vh;
`;

const LinkWrapper = styled.div`
  position: absolute;
  right: 4%;
  top: 3%;
`;
