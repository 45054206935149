import styled from "styled-components";

export const Link = styled.button`
  border: none;
  border-bottom: 2px solid black;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;

  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;

  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;

  &:hover {
    cursor: pointer;
  }
`;
