import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  backendUrl,
  getSocket,
  PlayerAction,
  ServerMessages,
} from "../context/socket";
import { HighscoreData } from "../types";
import {
  GameStatus,
  PlayerGameState,
  PlayerScreen,
} from "../Screens/ScreenSelector";

export const playerApi = createApi({
  reducerPath: "player",
  baseQuery: fetchBaseQuery({ baseUrl: backendUrl() }),
  endpoints: (builder) => ({
    joinRoom: builder.mutation<void, { code: string }>({
      queryFn: ({ code }) => {
        const socket = getSocket();
        return new Promise((resolve) => {
          socket.emit(PlayerAction.JoinRoom, { code }, (res: any) =>
            resolve({ data: res })
          );
        });
      },
    }),
    setNick: builder.mutation<void, { nick: string }>({
      queryFn: ({ nick }) => {
        const socket = getSocket();
        return new Promise((resolve) => {
          socket.emit(PlayerAction.SetNickname, { nick }, (res: any) =>
            resolve({ data: res })
          );
        });
      },
    }),
    sendChatMessage: builder.mutation<
      void,
      { textMsg: string; playerNick: string }
    >({
      queryFn: ({ textMsg, playerNick }) => {
        const socket = getSocket();
        return new Promise((resolve) => {
          socket.emit(PlayerAction.Chat, { textMsg, playerNick }, (res: any) =>
            resolve({ data: res })
          );
        });
      },
    }),
    submitAnswer: builder.mutation<void, { answer: number }>({
      queryFn: ({ answer }) => {
        const socket = getSocket();
        return new Promise((resolve) => {
          socket.emit(PlayerAction.Answer, { answer }, (res: any) =>
            resolve({ data: res })
          );
        });
      },
    }),
    recoverSession: builder.mutation<void, { data: any }>({
      queryFn: ({ data }) => {
        const socket = getSocket();
        return new Promise((resolve) => {
          socket.emit(PlayerAction.Recover, { data }, (res: any) =>
            resolve({ data: res })
          );
        });
      },
    }),

    getState: builder.query<PlayerGameState, void>({
      queryFn: () => ({
        data: {
          screen: PlayerScreen.Join,
          nick: null,
          roomName: null,
          alert: null,
          id: null,
          current: null,
          gameStatus: GameStatus.NotStarted,
          currentQuestion: {
            remainingTimeToAnswer: -1,
            correctAnswer: null,
            playerAnswer: null,
          },
          score: 0,
          previousAnswers: [],
          previousCorrectAnswers: [],
          allHighscores: [],
          finished: false,
        },
      }),
      async onCacheEntryAdded(
        arg,
        { cacheDataLoaded, cacheEntryRemoved, updateCachedData }
      ) {
        try {
          await cacheDataLoaded;
          const socket = getSocket();
          // Transition player to set nick
          socket.on(
            ServerMessages.JoinedRoom,
            (data: {
              title: string;
              id: string;
              current: number;
              code?: boolean;
            }) => {
              const { title, id, current, code } = data;
              if (code === false) {
                return;
              }
              updateCachedData((draft) => {
                draft.screen = PlayerScreen.SetNick;
                draft.roomName = title;
                draft.id = id;
                draft.current = current;
              });
            }
          );

          socket.on(ServerMessages.Question, () => {
            updateCachedData((draft) => {
              draft.currentQuestion.correctAnswer = null;
              draft.screen = PlayerScreen.Question;
            });
          });

          // Player is ready, transition to waiting screen
          socket.on(ServerMessages.Ready, (data) => {
            updateCachedData((draft) => {
              draft.screen = PlayerScreen.Waiting;
            });
          });

          // Something bad happend, we panik
          socket.on(ServerMessages.Reset, () => {
            window.location.reload();
          });

          socket.on(ServerMessages.Tick, (data: { time: number }) => {
            updateCachedData((draft) => {
              draft.currentQuestion.remainingTimeToAnswer = data.time;
            });
          });

          socket.on(ServerMessages.SetNick, (data: { nick: string }) => {
            console.log("Set Nick!", data);
            updateCachedData((draft) => {
              draft.nick = data.nick;
            });
          });

          // TODO: This state is too messy compared to the other
          //       serverMessage handlers, consier refactoring
          socket.on("highscore", (data: HighscoreData) => {
            const { status, highscore, answer } = data;
            console.log("Game running", data);

            if (highscore) {
              updateCachedData((draft) => {
                if (status === "between question" || status === "finished") {
                  const playerData = highscore.find(
                    (user) => user.id === draft.id
                  );

                  draft.screen = PlayerScreen.Answer;
                  draft.allHighscores.push(highscore);

                  if (!playerData) return;
                  draft.score = playerData.score;
                }
              });
            }
            if (status) {
              if (status === "between question") {
                updateCachedData((draft) => {
                  draft.currentQuestion.correctAnswer = answer;
                  draft.previousCorrectAnswers.push(answer);
                  draft.screen = PlayerScreen.Answer;
                });
              }
              // Quiz starts
              if (status === "start") {
                console.log("Start!");
                updateCachedData((draft) => {
                  draft.screen = PlayerScreen.Question;
                  draft.currentQuestion.correctAnswer = null;
                  draft.currentQuestion.playerAnswer = null;
                });
              }
              if (status === "finished") {
                updateCachedData((draft) => {
                  draft.finished = true;
                  draft.currentQuestion.correctAnswer = answer;
                });
              }
            }
          });

          await cacheEntryRemoved;
        } catch (error) {}
      },
    }),
  }),
});

export const {
  useJoinRoomMutation,
  useGetStateQuery,
  useSetNickMutation,
  useSendChatMessageMutation,
  useSubmitAnswerMutation,
  useRecoverSessionMutation,
} = playerApi;
